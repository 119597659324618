<template>
    <el-dialog
        width="800px"
        :visible.sync="visible"
        :title="title"
        :before-close="handleCancel"
        :destroy-on-close="true"
        :close-on-click-modal="false"
        class="update-dialog"
    >
        <el-form ref="form" :model="formData" :rules="rules">
            <el-row>
                <el-col :span="12">
                    <el-form-item
                        label="动作"
                        label-width="140px"
                        class="weight500"
                        prop="sellAction.actionName"
                    >
                        <el-input
                            v-model.trim="formData.sellAction.actionName"
                            placeholder="输入动作名称"
                            class="w240"
                            clearable
                            :disabled="dialogType === 'info'"
                        ></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item
                        label="主题"
                        label-width="140px"
                        class="weight500"
                        prop="sellAction.actionSubject"
                    >
                        <gb-dictionary
                            ref="xssj"
                            v-model="formData.sellAction.actionSubject"
                            class="w240"
                            group="销售运营动作主题"
                            :clearable="true"
                            :filterable="true"
                            placeholder="主题选择"
                            :disabled="dialogType === 'info'"
                        >
                        </gb-dictionary>
                    </el-form-item>
                </el-col>
                <!-- todo: -->
                <el-col :span="12">
                    <el-form-item
                        label="监控时长"
                        label-width="140px"
                        class="weight500"
                        prop="sellAction.monitorInterval"
                    >
                        <el-input-number
                            v-model="formData.sellAction.monitorInterval"
                            :min="0"
                            :max="1000"
                            label="输入监控时长"
                            :disabled="dialogType === 'info'"
                        ></el-input-number>
                    </el-form-item>
                </el-col>
                <el-col :span="24">
                    <el-form-item
                        label="说明"
                        label-width="140px"
                        class="weight500"
                        prop="sellAction.description"
                    >
                        <el-input
                            v-model="formData.sellAction.description"
                            class="w480"
                            type="textarea"
                            clearable
                            maxlength="200"
                            show-word-limit
                            :autosize="{ minRows: 3, maxRows: 5 }"
                            :disabled="dialogType === 'info'"
                        ></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-divider></el-divider>
            <div class="search-list">
                <!-- <div v-for="(item, i) in formData.sellSkuList" :key="item.id">
                    <h4>
                        <span>{{ item.skuTitle }}</span>
                        <el-button type="text" @click="handleRemove(i, item)">
                            删除
                        </el-button>
                    </h4>
                    <el-row>
                        <el-col class="item-desc" :span="12">
                            商品编号：{{ item.skuNo }}
                        </el-col>
                        <el-col class="item-desc" :span="12">
                            规格：{{ item.skuProperty || '-' }}
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                class="custom-item"
                                label="简称"
                                :prop="`sellSkuList[${i}].skuAlias`"
                                :rules="arrayRule.skuAlias"
                            >
                                <el-input
                                    v-model="item.skuAlias"
                                    class="w240"
                                    clearable
                                ></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                class="custom-item"
                                label="平台SKU ID"
                                :prop="`sellSkuList[${i}].platformSkuNo`"
                                :rules="arrayRule.platformSkuNo"
                            >
                                <el-input
                                    v-model="item.platformSkuNo"
                                    class="w240"
                                    clearable
                                ></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-divider
                        v-if="formData.sellSkuList.length - 1 !== i"
                    ></el-divider>
                </div> -->
                <template v-if="visible">
                    <form-item
                        v-for="(item, i) in formData.sellActionIndicators"
                        :key="item.id"
                        :item="item"
                        :index="i"
                        :visible="visible"
                        :disabled="dialogType === 'info'"
                        @handle-validate-filed="handleValidateFiled"
                        @handle-remove="handleRemove"
                    ></form-item>
                </template>
            </div>
            <div v-if="dialogType !== 'info'" class="add-footer">
                <span @click="handleAddOption">
                    <i
                        class="iconfont icon-benzhouxinzengfenxiaoshang"
                    />新增监控指标
                </span>
            </div>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button v-if="dialogType !== 'info'" type="primary" @click="handleConfirm()">保存</el-button>
            <el-button @click="handleCancel">取消</el-button>
        </span>
    </el-dialog>
</template>
<script>
import _ from 'lodash'
import { mapState } from 'vuex'
import sellSvc from 'services/sell'
import FormItem from './formItem.vue'

const gbDictionary = () => import('@/components/dictionary')

export default {
    components: {
        FormItem,
        gbDictionary
    },
    props: {
        // form: {
        //     type: Object,
        //     default: () => {}
        // }
    },
    data () {
        return {
            formData: {
                sellAction: {},
                sellActionIndicators: []
            },
            formDataClone: {
                sellAction: {},
                sellActionIndicators: []
            },
            removeList: [],
            visible: false,
            dialogType: '',
            rules: {
                'sellAction.actionName': [
                    {
                        required: true,
                        message: '请输入动作名称',
                        trigger: 'blur'
                    },
                    {
                        min: 1,
                        max: 50,
                        message: '长度在 2 到 50 个字符，且不能为空格',
                        trigger: 'blur'
                    }
                ],
                'sellAction.actionSubject': [
                    {
                        required: true,
                        message: '请选择主题',
                        trigger: 'change'
                    }
                ],
                'sellAction.monitorInterval': [
                    {
                        required: true,
                        message: '请输入监控时长',
                        trigger: 'blur'
                    }
                ],
                'sellAction.description': [
                    {
                        required: false,
                        message: '请输入说明',
                        trigger: 'blur'
                    },
                    {
                        min: 1,
                        max: 200,
                        message: '长度在 1 到 200 个字符',
                        trigger: 'blur'
                    }
                ]
            }
        }
    },
    computed: {
        title () {
            const titles = {
                add: '添加',
                info: '详情',
                edit: '编辑'
            }
            return titles[this.dialogType]
        }
    },
    // watch: {
    //     form: {
    //         immediate: true,
    //         deep: true,/sell-action/action-indicator-detail
    //         handler (newVal) {
    //             this.formData = _.cloneDeep(newVal)
    //         }
    //     }
    // },
    methods: {
        async handleDialog (id, dialogType = 'add') {
            this.dialogType = dialogType
            let form = {}
            if (['edit', 'info'].includes(dialogType)) {
                const { data } = await sellSvc.getActionDetail({ id })
                form = data
            } else {
                form = {
                    sellAction: {},
                    sellActionIndicators: []
                }
            }
            this.formData = _.cloneDeep(form)
            this.formDataClone = _.cloneDeep(form)
            this.handleVisible(true)
            this.$nextTick(() => {
                this.clearValidate()
            })
        },
        handleVisible (boo) {
            this.visible = boo
        },
        handleAddOption () {
            const form = {
                id: null, // 更新时此属性有值，新建时此属性没有值
                categoryId: null, // 数据分类ID
                dataFrom: null, // 数据来源
                sourceId: null, // 流量来源ID
                indicatorId: null, // 监控指标ID
                dataRange: null, // 数据范围
                dataRangeDetailType: null, // 数据范围细分类型
                clientDeleteFlg: null // 是删除
            }
            this.formData.sellActionIndicators.push(form)
        },
        handleRemove (i, { id }) {
            this.removeList.push(id)
            this.formData.sellActionIndicators.splice(i, 1)
            this.$nextTick(() => {
                this.clearValidate()
            })
        },
        handleConfirm () {
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.operationCategory()
                } else {
                    console.log('error submit!!')
                    return false
                }
            })
        },
        handleCancel () {
            // Object.keys(this.form).forEach(item => {
            //     if(item === 'sellSkuList') {
            //         this.form[item] = []
            //     } else {
            //         this.form[item] = null
            //     }
            // })
            this.handleVisible(false)
        },
        clearValidate () {
            this.$refs.form.clearValidate()
        },
        resetForm () {
            this.$refs.form.resetFields()
        },
        handleValidateFiled (field) {
            this.$refs.form.validateField(field)
        },
        async operationCategory () {
            const form = _.cloneDeep(this.formData)
            const removeList = this.formDataClone.sellActionIndicators.reduce((acc, cur) => {
                if (this.removeList.includes(cur.id)) {
                    cur.clientDeleteFlg = true
                    acc.push(cur)
                }
                return acc
            }, [])
            form.sellActionIndicators.push(...removeList)
            const data = await sellSvc.saveActionIndicator(form)
            this.$emit('handle-refresh')
            this.handleVisible(false)
        }
    }
}
</script>
<style lang="scss" scoped>
.update-dialog {
    .w480 {
        width: 480px;
    }

    .w240 {
        width: 240px;
    }

    .weight500 {
        font-weight: 500;
    }

    ::v-deep.el-dialog__body {
        max-height: 600px;
        padding: 33px 0 21px;
        overflow-y: auto;
    }

    ::v-deep.el-dialog__footer {
        padding: 10px;
        border-top: 2px solid #f0f0f0;

        .el-button {
            width: auto;
            height: auto;
            padding: 10px 18px;
            font-size: 14px;
            border-radius: 8px;
        }
    }

    .search-list {
        padding: 0 37px;
    }

    .add-footer {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        font-weight: 400;
        color: #3595e1;

        span {
            cursor: pointer;
        }

        i {
            margin-right: 7px;
        }
    }
}
</style>
