<template>
    <div class="form-item">
        <h4>
            <span>动作{{ index + 1 }}</span>
            <el-button v-if="!disabled" type="text" @click="handleRemove(index, item)">
                删除
            </el-button>
        </h4>
        <el-row>
            <!-- 数据分组 -->
            <el-col class="item-desc" :span="12">
                <el-form-item
                    class="weight500"
                    label-width="100px"
                    label="数据分组"
                    :prop="`sellActionIndicators[${index}].categoryId`"
                    :rules="rules.categoryId"
                >
                    <el-select
                        v-model="item.categoryId"
                        class="w240"
                        :filterable="true"
                        clearable
                        :disabled="disabled"
                        @change="handleCategoryId($event, index)"
                    >
                        <el-option
                            v-for="result in transfer.category"
                            :key="result.id"
                            :label="result.title"
                            :value="result.id"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-col>

            <el-col class="item-desc" :span="12">
                <el-form-item
                    class="weight500"
                    label-width="100px"
                    label="数据来源"
                    :prop="`sellActionIndicators[${index}].dataFrom`"
                    :rules="rules.dataFrom"
                >
                    <gb-dictionary
                        ref="xssj"
                        v-model="item.dataFrom"
                        class="w240"
                        :level="1"
                        :options-filter="optionsFilterSource"
                        :transfer="true"
                        group="销售数据来源"
                        :disabled="disabled"
                        @change="handleDataFrom($event, `sellActionIndicators[${index}].dataFrom`, index)"
                        @items-change="itemsChange"
                    ></gb-dictionary>
                </el-form-item>
            </el-col>
            <el-col v-show="dataRangeTypeVisible" class="item-desc" :span="24">
                <el-form-item
                    class="weight500"
                    label-width="100px"
                    label="数据范围"
                    :prop="`sellActionIndicators[${index}].dataRange`"
                    :rules="item.dataFrom ? rules.dataRange : [{required: false}]"
                >
                    <gb-dictionary
                        v-model="item.dataRange"
                        class="mr10 w240"
                        :level="2"
                        :options-filter="list => optionsFilter(list, 1)"
                        group="销售数据来源"
                        :disabled="disabled"
                        @change="handleValidateFiled(`sellActionIndicators[${index}].dataRange`)"
                    ></gb-dictionary>
                    <gb-dictionary
                        v-model="item.dataRangeDetailType"
                        class="mr10 w240"
                        :level="2"
                        :options-filter="list => optionsFilter(list, 2)"
                        group="销售数据来源"
                        :disabled="disabled"
                        @change="handleValidateFiled(`sellActionIndicators[${index}].dataRange`)"
                    ></gb-dictionary>
                </el-form-item>
            </el-col>
            <el-col v-if="item.categoryId === '2'" class="item-desc" :span="12">
                <el-form-item
                    class="weight500"
                    label-width="100px"
                    label="流量来源"
                    :prop="`sellActionIndicators[${index}].sourceId`"
                    :rules="item.categoryId === '2' ? rules.sourceId : [{required: false}]"
                >
                    <!-- <el-select
                        v-model="item.sourceId"
                        class="w240"
                        :filterable="true"
                        clearable
                    >
                        <el-option
                            v-for="result in sourceItemValues"
                            :key="result.indicatorId"
                            :label="result.title"
                            :value="result.indicatorId"
                        >
                        </el-option>
                    </el-select> -->
                    <el-cascader
                        :ref="`cascaderHandle${index}`"
                        :key="index"
                        v-model="item.sourceId"
                        style="width: 240px;"
                        :options="sourceItemValues"
                        :props="{
                            checkStrictly: true,
                            value: 'id',
                            label: 'sourceName',
                            emitPath: false,
                        }"
                        :disabled="disabled"
                        clearable
                        :filterable="true"
                        @change="handleClose($event, index)"
                    ></el-cascader>
                </el-form-item>
            </el-col>
            <el-col class="item-desc" :span="12">
                <el-form-item
                    class="weight500"
                    label-width="100px"
                    label="指标"
                    :prop="`sellActionIndicators[${index}].indicatorId`"
                    :rules="rules.indicatorId"
                >
                    <el-select
                        v-model="item.indicatorId"
                        class="w240"
                        :filterable="true"
                        clearable
                        :disabled="disabled"
                    >
                        <el-option
                            v-for="result in indicator"
                            :key="result.indicatorId"
                            :label="result.title"
                            :value="result.indicatorId"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-col>
        </el-row>
        <el-divider></el-divider>
    </div>
</template>
<script>
import _ from 'lodash'
import sellSvc from 'services/sell'
import gbDictionary from '@/components/dictionary'

export default {
    components: {
        gbDictionary
    },
    props: {
        item: {
            type: Object,
            default: () => {}
        },
        index: {
            type: Number,
            default: 0
        },
        disabled: {
            type: Boolean,
            default: false
        },
        visible: {
            type: Boolean,
            default: false
        }
    },
    inject: ['transfer'],
    data () {
        return {
            itemForm: {},
            indicator: [],
            rules: {
                categoryId: [
                    {
                        required: true,
                        message: '请选择数据分组',
                        trigger: 'change'
                    }
                ],
                dataFrom: [
                    {
                        required: true,
                        message: '请选择数据来源',
                        trigger: 'change'
                    }
                ],
                dataRange: [
                    {
                        required: true,
                        // message: '请选择数据范围',
                        trigger: 'change',
                        validator: this.dataValidator
                    }
                ],
                sourceId: [
                    {
                        required: true,
                        message: '请选择流量来源',
                        trigger: 'change'
                    }
                ],
                indicatorId: [
                    {
                        required: true,
                        message: '请选择指标',
                        trigger: 'change'
                    }
                ]
            },
            dictionary: [],
            sourceItemValues: [],
            isFirst: true
        }
    },
    computed: {
        watchObj () {
            return {
                visible: this.visible,
                categoryId: this.item.categoryId
            }
        },
        dataRangeTypeVisible () {
            console.log(this.dictionary)
            if (_.isEmpty(this.dictionary)) {
                return false
            }
            const { dataFrom } = this.item
            return this.dictionary.some(
                ({ parentNo, intValue, itemGroup }) =>
                    parentNo == dataFrom &&
                    [1, 2].includes(intValue * 1) &&
                    itemGroup === '销售数据来源'
            )
        }
    },
    watch: {
        watchObj: {
            immediate: true,
            async handler (newVal) {
                if (newVal && newVal.categoryId && newVal.visible) {
                    const { data } = await sellSvc.getValueIndicator({ categoryId: newVal.categoryId })
                    this.indicator = data
                } else {
                    this.indicator = []
                }
            }
        },
        'item.dataFrom': {
            immediate: true,
            handler (newVal) {
                if (newVal) {
                    this.getSource()
                } else {
                    this.sourceItemValues = []
                }
            }
        }
    },
    created () {
        gbDictionary.init(this)
    },
    methods: {
        dataValidator (rule, value, callback) {
            if (this.item.dataRange || this.item.dataRangeDetailType) {
                callback()
                return
            }
            callback(new Error('请选择数据范围'))
        },
        handleRemove (i, item) {
            this.$emit('handle-remove', i, item)
        },
        getValueIndicator () {

        },
        handleCategoryId (val, index) {
            this.item.indicatorId = null
            this.item.sourceId = null
            this.handleValidateFiled(`sellActionIndicators[${index}].indicatorId`)
        },
        handleDataFrom (val, form, index) {
            this.sourceItem = val
            this.handleValidateFiled(form)
            if (!this.isFirst) {
                this.item.dataRange = null
                this.item.dataRangeDetailType = null
                this.item.sourceId = null
                this.handleValidateFiled(`sellActionIndicators[${index}].sourceId`)
            }
            this.isFirst = false
        },
        itemsChange (val) {
            console.log(val)
            this.dictionary = val || []
        },
        handleValidateFiled (field) {
            this.$emit('handle-validate-filed', field)
        },
        optionsFilterSource (list) {
            if (!list || list.length <= 0) {
                return list
            }
            let list1 = []
            list.forEach(item => {
                list1.push(item)
            })
            return list1
        },
        optionsFilter (list, value) {
            console.log(list, value)
            if (!list || list.length <= 0) {
                return list
            }
            let list1 = []
            list.forEach(item => {
                if (item.intValue == value) {
                    list1.push(item)
                }
            })
            return list1
        },
        async getSource () {
            const { data: source } = await sellSvc.getSellVisitSourceTree({
                dataFrom: this.item.dataFrom
            })
            this.sourceItemValues = source
        },
        handleClose (val, index) {
            const cascader = this.$refs[`cascaderHandle${index}`]
            cascader.dropDownVisible = false
            if (!val) {
                // 清空已选择项
                cascader.$refs.panel.checkedValue = [] // 也可以是指定的值
                cascader.$refs.panel.calculateCheckedNodePaths()
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.form-item {
    .w240 {
        width: 240px;
    }

    .weight500 {
        font-weight: 500;
    }

    .mr10 {
        margin-right: 10px;
    }

    h4 {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;

        span {
            font-size: 14px;
            font-weight: 500;
            line-height: 24px;
            color: #c0c4cc;
        }
    }

    .item-desc {
        margin-bottom: 20px;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        color: #606266;
    }
}
</style>
